import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  serverEndpoint = `${environment.apiUrl}`;
  constructor(private http: HttpClient) { }
  getLivePrice() {
    const endPoint = `/liverate`;
    return this.http.get(this.serverEndpoint + endPoint);
  }
  bookGoldAndSilver(data: any) {
    const endPoint = `booking`;
    return this.http.post(this.serverEndpoint + endPoint, data);
  }
  buyGoldAnsSilver(data: any) {
    const endPoint = `order`;
    return this.http.post(this.serverEndpoint + endPoint, data);
  }
  kycPost(data: any) {
    const endPoint = `KYCupdate`;
    return this.http.post(this.serverEndpoint + endPoint, data);
  }
  imgUpload(data: any) {
    const endPoint = `multipleImageUpload`;
    return this.http.post(this.serverEndpoint + endPoint, data);
  }
  aboutUs() {
    const endPoint = `aboutus`;
    return this.http.get(this.serverEndpoint + endPoint);
  }
  ordersGet(type: string) {
    const endPoint = `getMyOrders/${type}`;
    return this.http.get(this.serverEndpoint + endPoint);
  }
  receiptUpdate(data: any) {
    const endPoint = `upload-transaction`;
    return this.http.post(this.serverEndpoint + endPoint, data);
  }
  getGoldLimit() {
    const endPoint = `auth/getMaxLimit`;
    return this.http.get(this.serverEndpoint + endPoint);
  }
  getSilverLimit() {
    const endPoint = `auth/getSilverMaxLimit`;
    return this.http.get(this.serverEndpoint + endPoint);
  }
  postAlertrate(data: any) {
    const endPoint = `alert`;
    return this.http.post(this.serverEndpoint + endPoint, data);
  }
  getMargin() {
    const endPoint = `getMargins`;
    return this.http.get(this.serverEndpoint + endPoint)
  }
  silverGetMargin() {
    const endPoint = `getSilverMargins`;
    return this.http.get(this.serverEndpoint + endPoint)
  }
  chartData(data: any) {
    const endPoint = `recordsByTime`;
    return this.http.post(this.serverEndpoint + endPoint, data);
  }
  updateOrderStatus(data:any){
    const endPoint=`changeOrderStatus`;
    return this.http.post(this.serverEndpoint+endPoint,data);
  }
  getUserKyc(){
    const endPoint=`getKyc`;
    return this.http.get(this.serverEndpoint+endPoint);
  }
  deliveryMsg(id:number){
    const endPoint=`getDeliveryDetails/${id}`;
    return this.http.get(this.serverEndpoint+endPoint);
  }
  userNewPersonDetailspost(data:any){
    const endPoint=`deliveryOtherPerson`;
    return this.http.post(this.serverEndpoint+endPoint,data);
  }
  getNotification(): Observable<any>{
    const endPoint=`notification`;
    return this.http.get(this.serverEndpoint+endPoint);
  }
  notificationRead(){
    const endPoint=`notifications/markAsRead`;
    return this.http.post(this.serverEndpoint+endPoint,{})
  }
  singleImgUpload(data:any){
    const endPoint=`imageupload`;
    return this.http.post(this.serverEndpoint+endPoint,data);
  }


  public apiUrlForLivePriceFromDb = 'https://liverates-api.goldcentral.in/api/liveRateDB';



  getInitialRates(): Observable<any> {
    return this.http.get<any>(this.apiUrlForLivePriceFromDb);
  }
  public apiUrlForLivePriceForHistory= 'https://liverates-api.goldcentral.in/api/'

  getMultipleGoldRecords(days: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrlForLivePriceForHistory}/getMultipleGoldRecord/${days}`);
  }

}
