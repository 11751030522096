<div class="profile-main">
    <div class="child">
        <div class="round">
            <h3 class="user-name">{{userLog?.proprietor_name | slice: 0 : 1 }}</h3>
        </div>
        <p class="name">{{userLog?.proprietor_name}}</p>
        <p class="num">{{userLog?.register_phone_number}}</p>
        <p class="num">{{userLog?.email}}</p>
        <!-- <p class="num">{{userLog?.}}</p> -->
    </div>
</div>

<div class="account-main">
    <p class="account">Account Information</p>
    <p class="find">Find all your profile related information</p>

    <div class="main-kyc">
        <div>
            <img class="usericon" src="../../../assets/user.png" alt="">
        </div>
        <div>
            <p class="user-information">User Information <span><button class="start-btn"
                        routerLink="/mobile/kyc_details" *ngIf="userLog?.kyc_status!=0">Start Now</button><button
                        class="start-btn" routerLink="/mobile/update-kyc" *ngIf="userLog?.kyc_status==0">Start
                        Now</button></span></p>
            <p class="Complete">Complete your KYC to Buy, Book</p>
        </div>
        <div routerLink="/mobile/kyc_details" *ngIf="userLog?.kyc_status!=0">
            <img class="keyboard" src="../../../assets/Keyboard arrow right.png" alt="">
        </div>
        <div routerLink="/mobile/update-kyc" *ngIf="userLog?.kyc_status==0">
            <img class="keyboard" src="../../../assets/Keyboard arrow right.png" alt="">
        </div>


    </div>

    <p class="how-can">How can we help?</p>
    <p class="we-are">We’re here for you 24x7</p>


    <div class="create-new-main">
        <img class="profile-img" src="../../../assets/add-profile 1.png" alt="">
        <p class="create-para">Get In Touch</p>
        <button class="contact-us-btn" routerLink="/mobile/booking">Contact Us</button>
    </div>
    <div class="create-new-main">
        <!-- <img class="profile-img" src="../../../assets/add-profile 1.png" alt="">
        <span class="profile-img"><i class="fa-solid fa-trash"></i></span> -->
        <p class="create-para">Delete Account</p>
        <button class="contact-us-btn" (click)="deleteUser()"><i class="fa-solid fa-trash"></i></button>
    </div>


    <button class="logout-btn" (click)="logOut()">Logout</button>
</div>
